import styles from './tab1cms.module.css';
import { useNavigate } from 'react-router-dom';

export default function Tab1CMS() {

    const navigate = useNavigate();

    const handleNavigation = (path) => {
        navigate(path);
    };

    return (
        <div>
            <h1 style={{ textAlign: 'center' }}>Tab 1 CMS</h1>

            <div className={styles.gridWrapper}>
                <div className={styles.gridContainer}>
                    <div className={styles.itemContainer} onClick={() => handleNavigation('/cms/dashboard/tab1cms/upload-logo')}>
                        <span className={styles.labelDashboard}>Upload Logo</span>
                    </div>

                    <div className={styles.itemContainer} onClick={() => handleNavigation('/cms/dashboard/tab1cms/upload-news')}>
                        <span className={styles.labelDashboard}>Upload News Article</span>
                    </div>

                    <div className={styles.itemContainer} onClick={() => handleNavigation('/cms/dashboard/tab1cms/upload-banners')}>
                        <span className={styles.labelDashboard}>Upload Banner Image</span>
                    </div>

                    <div className={styles.itemContainer} onClick={() => handleNavigation('/cms/dashboard/tab1cms/upload-contact-info')}>
                        <span className={styles.labelDashboard}>Upload Contact Info</span>
                    </div>

                    <div className={styles.itemContainer} onClick={() => handleNavigation('/cms/dashboard/tab1cms/upload-lottery-results')}>
                        <span className={styles.labelDashboard}>Upload Lottery Result</span>
                    </div>

                    <div className={styles.itemContainer} onClick={() => handleNavigation('/cms/dashboard/tab1cms/upload-introduction-app')}>
                        <span className={styles.labelDashboard}>Upload Introduction App</span>
                    </div>

                    <div className={styles.itemContainer} onClick={() => handleNavigation('/cms/dashboard/tab1cms/upload-title-descriptions')}>
                        <span className={styles.labelDashboard}>Upload Title & Description</span>
                    </div>

                    <div className={styles.itemContainer} onClick={() => handleNavigation('/cms/dashboard/tab1cms/upload-footer')}>
                        <span className={styles.labelDashboard}>Upload Footer Content</span>
                    </div>

                </div>
            </div>



        </div>
    );
}