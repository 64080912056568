import React from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './dashboard.module.css';

function Dashboard() {
    const navigate = useNavigate();

    const handleNavigation = (path) => {
        navigate(path);
    };

    return (
        <div>
            <h1 style={{ textAlign: 'center' }}>Welcome to CMS Dashboard</h1>

            <div className={styles.gridWrapper}>
                <div className={styles.gridContainer}>
                    <div className={styles.itemContainer} onClick={() => handleNavigation('/cms/dashboard/tab1cms')}>
                        <span className={styles.labelDashboard}>Tab 1</span>
                    </div>

                    <div className={styles.itemContainer} onClick={() => handleNavigation('/tab2')}>
                        <span className={styles.labelDashboard}>Tab 2</span>
                    </div>

                    <div className={styles.itemContainer} onClick={() => handleNavigation('/tab3')}>
                        <span className={styles.labelDashboard}>Tab 3</span>
                    </div>

                    <div className={styles.itemContainer} onClick={() => handleNavigation('/tab4')}>
                        <span className={styles.labelDashboard}>Tab 4</span>
                    </div>

                    <div className={styles.itemContainer} onClick={() => handleNavigation('/tab5')}>
                        <span className={styles.labelDashboard}>Tab 5</span>
                    </div>

                    <div className={styles.itemContainer} onClick={() => handleNavigation('/tab6')}>
                        <span className={styles.labelDashboard}>Tab 6</span>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Dashboard;
